import React from "react";
import Navbar from "../components/Navbar";
import Margen from "../components/Margen";
import Footer from "../components/Footer";
import Panel_Contacto from "../components/Panel_Contacto";

function Contacto() {
  return (
    <div>
      <Navbar />
      <Margen />
      <Panel_Contacto />
      <Footer />
    </div>
  );
}

export default Contacto;
