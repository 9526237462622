import React, { useState, useEffect } from "react";
import "../css/Panel_Descargas.css";
import axios from "axios";

function Panel_Descargas() {
  const [descargas, setDescargas] = useState([]);
  const fetchDescargas = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agrouls_backend/descargas.php`
    );
    setDescargas(response.data);
  };
  useEffect(() => {
    fetchDescargas();
  }, []);
  return (
    <div className="ContenedorDescargas">
      <h1>Descargas</h1>
      <p>Para ver la revista en línea, debes pulsar sobre la portada.</p>
      <div className="contenedorArchivos">
        {descargas.map((descarga, index) => (
          <div key={index}>
            <p>{descarga.titulo}</p>
            <p className="linkDescarga">
              Descargar revista N° {descarga.orden * 1 + 1}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Panel_Descargas;
