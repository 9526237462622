import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import "../../css/Admin.css";

function App() {
  const currentYear = new Date().getFullYear();

  // Generar la lista de años desde el año actual hasta 1900
  const availableYears = [];
  for (let y = currentYear; y >= 2010; y--) {
    availableYears.push(y);
  }

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [year, setYear] = useState(currentYear);
  const [editYear, setEditYear] = useState(2018);
  const [image, setImage] = useState(null);
  const [editImage, setEditImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setPreviewUrl(URL.createObjectURL(file)); // Crear una URL de objeto para la vista previa
    } else {
      setPreviewUrl(null);
    }
  };

  const handleEditImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setEditImage(file);
      setPreviewUrl(URL.createObjectURL(file)); // Crear una URL de objeto para la vista previa
    } else {
      setPreviewUrl(null);
    }
  };

  const openModal = () => {
    setModalIsOpen(true);
    setPreviewUrl(null);
    setYear(currentYear);
  };
  //Cierra el modal
  const closeModal = () => {
    setModalIsOpen(false);
    setPreviewUrl(null);
    setYear(currentYear);
  };
  //Abre el modal Edit
  const openEditModal = () => {
    setEditModalIsOpen(true);
    setPreviewUrl(null);
    setYear(2018);
  };

  //Cierra el modal Edit
  const closeEditModal = () => {
    setEditModalIsOpen(false);
    setPreviewUrl(null);
    setYear(2018);
  };

  return (
    <div className="inicioDashboard">
      <h2>Bienvenido al Dashboard de Administración</h2>
      <p>
        En este Dashboard puedes modificar los apartados de la página web. Usa
        la barra lateral para navegar entre los distintos apartados disponibles.
        Cada apartado tiene su propia tabla que permite gestionar la información
        mostrada en la página.
      </p>
      <h3>¿Cómo usar la tabla?</h3>
      <ul>
        <li>
          <div className="explicacionBoton">
            <button className="añadirFila" onClick={openModal}>
              Añadir Fila
            </button>
            <p>Permite añadir una nueva fila a la tabla.</p>
          </div>
        </li>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Formulario de CRUD"
          className="modalDashboard"
          overlayClassName="modal-overlay">
          <form>
            <h1>Añadir Fila</h1>
            <div>
              <label>Añadir texto:</label>
              <input type="text" />
            </div>
            <div>
              <label>Añadir párrafo:</label>
              <textarea type="text" />
            </div>
            <div>
              <label>Añadir Año:</label>

              <select
                id="year-select"
                name="year"
                value={year}
                onChange={(e) => setYear(e.target.value)}>
                {availableYears.map((y) => (
                  <option key={y} value={y}>
                    {y}
                  </option>
                ))}
              </select>
            </div>
            <div className="añadirFecha">
              <label>Añadir Fecha:</label>
              <input type="date" />
            </div>
            <div className="selectorImagen">
              <label>Añadir Imagen:</label>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
              />
            </div>
            {previewUrl && (
              <div>
                <label>Vista previa de la imagen:</label>
                <img
                  src={previewUrl}
                  alt="Vista previa"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </div>
            )}
            <div className="submitCancel">
              <button onClick={closeModal}>Cancelar</button>
              <button onClick={closeModal}>Añadir</button>
            </div>
          </form>
        </Modal>
        <li>
          <div className="explicacionBoton">
            <button className="añadirFila" onClick={openEditModal}>
              Editar
            </button>
            <p>Permite editar la fila seleccionada.</p>
          </div>
        </li>
        <Modal
          isOpen={editModalIsOpen}
          onRequestClose={closeEditModal}
          contentLabel="Formulario de Edición"
          className="modalDashboard"
          overlayClassName="modal-overlay">
          <form>
            <h1>Editar</h1>
            <div>
              <label>Editar Texto:</label>
              <input type="text" value="Texto de ejemplo" required />
            </div>
            <div>
              <label>Editar Párrafo:</label>
              <textarea type="text" value="Párrafo de ejemplo" required />
            </div>
            <div>
              <label>Editar Año:</label>
              <select
                id="year-select"
                name="year"
                value={editYear}
                onChange={(e) => setEditYear(e.target.value)}
                required>
                {availableYears.map((y) => (
                  <option key={y} value={y}>
                    {y}
                  </option>
                ))}
              </select>
            </div>
            <div className="añadirFecha">
              <label>Editar Fecha:</label>
              <input type="date" value="2000-01-01" required />
            </div>
            <div>
              <label>Editar Imagen:</label>
              <input
                type="file"
                accept="image/*"
                onChange={handleEditImageChange}
              />
            </div>
            {previewUrl && (
              <div>
                <label>Vista previa de la imagen:</label>
                <img
                  src={previewUrl}
                  alt="Vista previa"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              </div>
            )}
            <div className="submitCancel">
              <button onClick={closeEditModal}>Cancelar</button>
              <button onClick={closeEditModal}>Guardar Cambios</button>
            </div>
          </form>
        </Modal>
        <li>
          <div className="explicacionBoton">
            <button className="añadirFila">Eliminar</button>
            <p>Elimina una fila de la tabla.</p>
          </div>
        </li>
        <li>
          <div className="explicacionBoton">
            <div className="botonesMover">
              <button className="añadirFila">↑</button>
              <button className="añadirFila">↓</button>
            </div>
            <p>
              Permite subir o bajar la fila seleccionada. (Esto afectrá el orden
              en el que se muestra la información en la página)
            </p>
          </div>
        </li>
      </ul>
      <p>
        Cualquier cambio que hagas en las tablas se reflejará al refrescar la
        página. Asegúrate de revisar los cambios antes de guardar para evitar
        errores en la presentación.
      </p>
      <h3>Consideraciones de uso</h3>
      <ul>
        <li>
          Algunos campos son <strong>obligatorios</strong> en algunos
          formulario. Asegúrate de llenarlos.
        </li>
        <li>
          Si el campo no es obligatorio, puedes dejarlo en blanco y no se
          mostrará en la página.
        </li>
        <li>
          Antes de eliminar un ítem, verifica que no sea necesario en otro
          apartado.
        </li>
        <li>
          Al editar una imagen, la imagen que se suba remplazará la que existía
          anteriormente.
        </li>
        <li>
          Al pegar texto dentro de un campo,{" "}
          <strong>no se consevarán las decoraciones o hipervínculos</strong>{" "}
          asociados al texto.
        </li>
        <li>
          El nombre de las imágenes subidas{" "}
          <strong>
            no deben contener tildes, eñes (Ñ) o algún caracter especial.
          </strong>{" "}
          De lo contrario la imagen no se mostrará correctamente.
        </li>
        <li>
          El peso de la imagen subida <strong>no debe superar los 16MB</strong>.
          Si la imagen es muy pesada, considera reducir su resolución antes de
          subirla.
        </li>
        <li>
          El archivo subido <strong>debe ser una imagen.</strong> No se permiten
          otros formatos. Los formatos permitidos son los siguientes:
          <ul>
            <li>.JPG</li>
            <li>.JPEG</li>
            <li>.PNG</li>
            <li>.GIF</li>
            <li>.WEBP</li>
            <li>.BMP</li>
            <li>.TIFF</li>
            <li>.ICO</li>
          </ul>
        </li>
      </ul>
    </div>
  );
}

export default App;
