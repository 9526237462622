import React, { useState, useEffect } from "react";
import "../css/Informacion.css";
import axios from "axios";

const Informacion = () => {
  const [informacion, setInformacion] = useState([]);

  const fetchInformacion = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agrouls_backend/informacion.php`
    );
    setInformacion(response.data);
  };

  useEffect(() => {
    fetchInformacion();
  }, []);

  const getColor = (index) => {
    const colors = ["#DF504B", "#1A61A6", "#384069", "#44AFAE"];
    return colors[index % colors.length];
  };

  return (
    <div className="Informacion">
      {informacion.map((info, index) => (
        <div
          className="contenedorInfo"
          style={{ backgroundColor: getColor(index) }}>
          <h1>{info.titulo}</h1>
          <p>{info.informacion}</p>
        </div>
      ))}
    </div>
  );
};

export default Informacion;
