import React, { useState, useEffect } from "react";
import "../css/Datos.css";
import imagenDatos from "../images/Frontis-de-la-ULS-Limari.jpg";
import axios from "axios";

function Datos() {
  const [datos, setDatos] = useState([]);

  const fetchDatos = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agrouls_backend/datos.php`
    );
    setDatos(response.data);
  };

  useEffect(() => {
    fetchDatos();
  }, []);

  return (
    <div className="Datos">
      <h1 className="tituloDatos">DATOS INTERESANTES</h1>
      <div className="contenedorImagenDatos">
        <div className="contenedorImagen">
          <img src={imagenDatos}></img>
        </div>
        <div className="contenedorDatos">
          <h1>ALGUNOS DATOS</h1>
          <div className="contenedorDatosIndividuales">
            {datos.map((dato, index) => (
              <div key={index} className="dato">
                <div className="logoDato">
                  <img
                    src={`${process.env.REACT_APP_API_URL}/agrouls_backend/uploads/${dato.imagen}`}></img>
                </div>
                <div className="contenedorTextoDato">
                  <h2>{dato.valor}</h2>
                  <p>{dato.titulo}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Datos;
