import React, { useState, useEffect } from "react";
import "../css/Panel_Nosotros.css";
import axios from "axios";

function Panel_Nosotros() {
  const [nosotros, setNosotros] = useState([]);
  const [mision, setMision] = useState([]);
  const [vision, setVision] = useState([]);

  const fetchNosotros = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agrouls_backend/nosotros_1.php`
    );
    setNosotros(response.data);
  };

  const fetchMision = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agrouls_backend/nosotros_2.php`
    );
    setMision(response.data);
  };

  const fetchVision = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agrouls_backend/nosotros_3.php`
    );
    setVision(response.data);
  };

  useEffect(() => {
    fetchNosotros();
    fetchMision();
    fetchVision();
  }, []);

  return (
    <div className="ContenedorNosotros">
      <h1>Nosotros</h1>
      {nosotros.map((nosotros, index) => (
        <div key={index}>
          <p className="firmaDirector">{nosotros.autor}</p>
          <p className="firmaDirector">{nosotros.cargo}</p>
          <p>{nosotros.textoSuperior}</p>
        </div>
      ))}
      <h4>Misión</h4>
      <ul>
        {mision.map((mision, index) => (
          <div key={index}>
            <li>{mision.mision}</li>
          </div>
        ))}
      </ul>
      <h4>Visión</h4>
      <ul>
        {vision.map((vision, index) => (
          <div key={index}>
            <li>{vision.vision}</li>
          </div>
        ))}
      </ul>
      {nosotros.map((nosotros, index) => (
        <div key={index}>
          <p>{nosotros.textoInferior}</p>
        </div>
      ))}
    </div>
  );
}

export default Panel_Nosotros;
