import React, { useState, useEffect } from "react";
import "../css/Panel_Instrucciones.css";
import logo from "../images/agua-tierra.png";
import axios from "axios";

function Panel_Instrucciones() {
  const [instrucciones, setInstrucciones] = useState([]);
  const [estructura, setEstructura] = useState([]);

  const fetchInstrucciones = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agrouls_backend/instrucciones_1.php`
    );
    setInstrucciones(response.data);
  };

  const fetchEstructura = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agrouls_backend/instrucciones_2.php`
    );
    setEstructura(response.data);
  };

  useEffect(() => {
    fetchInstrucciones();
    fetchEstructura();
  }, []);

  return (
    <div className="ContenedorInstrucciones">
      <h1>Instrucciones a los colaboradores</h1>
      <div className="contenedorLogoTexto">
        <img src={logo}></img>
        {instrucciones.map((instruccion, index) => (
          <div key={index}>
            <p>{instruccion.textoSuperior}</p>
          </div>
        ))}
      </div>
      <ul>
        {estructura.map((estructura, index) => (
          <div key={index}>
            <li>{estructura.estructura}</li>
          </div>
        ))}
      </ul>
      {instrucciones.map((instruccion, index) => (
        <div key={index}>
          <p>{instruccion.textoInferior}</p>
        </div>
      ))}
    </div>
  );
}

export default Panel_Instrucciones;
