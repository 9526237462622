import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Margen from "../components/Margen";
import Panel_Escuela from "../components/Panel_Escuela";

function Escuela() {
  return (
    <div>
      <Navbar />
      <Margen />
      <Panel_Escuela />
      <Footer />
    </div>
  );
}

export default Escuela;
