import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Margen from "../components/Margen";
import Panel_Instrucciones from "../components/Panel_Instrucciones";

function Instrucciones() {
  return (
    <div>
      <Navbar />
      <Margen />
      <Panel_Instrucciones />
      <Footer />
    </div>
  );
}

export default Instrucciones;
