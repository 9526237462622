import React from "react";
import "../css/Margen.css";

function Margen() {
  return (
    <div>
      <div className="margen"></div>
    </div>
  );
}

export default Margen;
