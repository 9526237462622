import React from "react";
import Admin_Perfil_1 from "./Admin_Perfil_1";
import Admin_Perfil_2 from "./Admin_Perfil_2";

function Admin_Perfil_Contenedor() {
  return (
    <div>
      <Admin_Perfil_1 />
      <Admin_Perfil_2 />
    </div>
  );
}

export default Admin_Perfil_Contenedor;
