import React, { useState } from "react";
import "../css/Dashboard.css";
import Collapsible from "react-collapsible";
import Admin_Dashboard from "../components/admin/Admin_Dashboard";
import Admin_Academicos from "../components/admin/Admin_Academicos";
import Admin_Actividades from "../components/admin/Admin_Actividades";
import Admin_Actualidad from "../components/admin/Admin_Actualidad";
import Admin_Footer from "../components/admin/Admin_Footer";
import Admin_Cuerpo from "../components/admin/Admin_Cuerpo";
import Admin_Datos from "../components/admin/Admin_Datos";
import Admin_Descargas from "../components/admin/Admin_Descargas";
import Admin_Documentos from "../components/admin/Admin_Documentos";
import Admin_Empleos from "../components/admin/Admin_Empleos";
import Admin_Informacion from "../components/admin/Admin_Informacion";
import Admin_Seminario from "../components/admin/Admin_Seminario";
import Admin_Slider from "../components/admin/Admin_Slider";
import Admin_Escuela_Contenedor from "../components/admin/Admin_Escuela_Contenedor";
import Admin_Perfil_Contenedor from "../components/admin/Admin_Perfil_Contenedor";
import Admin_CEC_Contenedor from "../components/admin/Admin_CEC_Contenedor";
import Admin_Nosotros_Contenedor from "../components/admin/Admin_Nosotros_Contenedor";
import Admin_Servicios_Contenedor from "../components/admin/Admin_Servicios_Contenedor";
import Admin_Instrucciones_Contenedor from "../components/admin/Admin_Instrucciones_Contenedor";

function Dashboard() {
  const [activeForm, setActiveForm] = useState("Admin_Dashboard");
  const renderForm = () => {
    switch (activeForm) {
      case "Admin_Escuela_Contenedor":
        return <Admin_Escuela_Contenedor />;
      case "Admin_Dashboard":
        return <Admin_Dashboard />;
      case "Admin_Academicos":
        return <Admin_Academicos />;
      case "Admin_Actividades":
        return <Admin_Actividades />;
      case "Admin_Actualidad":
        return <Admin_Actualidad />;
      case "Admin_CEC_Contenedor":
        return <Admin_CEC_Contenedor />;
      case "Admin_Footer":
        return <Admin_Footer />;
      case "Admin_Cuerpo":
        return <Admin_Cuerpo />;
      case "Admin_Datos":
        return <Admin_Datos />;
      case "Admin_Descargas":
        return <Admin_Descargas />;
      case "Admin_Documentos":
        return <Admin_Documentos />;
      case "Admin_Empleos":
        return <Admin_Empleos />;
      case "Admin_Informacion":
        return <Admin_Informacion />;
      case "Admin_Instrucciones_Contenedor":
        return <Admin_Instrucciones_Contenedor />;
      case "Admin_Nosotros_Contenedor":
        return <Admin_Nosotros_Contenedor />;
      case "Admin_Perfil_Contenedor":
        return <Admin_Perfil_Contenedor />;
      case "Admin_Seminario":
        return <Admin_Seminario />;
      case "Admin_Servicios_Contenedor":
        return <Admin_Servicios_Contenedor />;
      case "Admin_Slider":
        return <Admin_Slider />;
    }
  };
  return (
    <div className="ContenedorDashboard">
      <div className="sidebar">
        <h1 onClick={() => setActiveForm("Admin_Dashboard")}>Admin </h1>
        <Collapsible trigger="Inicio ⮟">
          <button onClick={() => setActiveForm("Admin_Slider")}>Slider</button>
          <button onClick={() => setActiveForm("Admin_Informacion")}>
            Información
          </button>
          <button onClick={() => setActiveForm("Admin_Actualidad")}>
            Actualidad
          </button>
          <button onClick={() => setActiveForm("Admin_Datos")}>Datos</button>
          <button onClick={() => setActiveForm("Admin_Actividades")}>
            Actividades
          </button>
        </Collapsible>
        <Collapsible trigger="Ingeniería Agronómica ⮟">
          <button onClick={() => setActiveForm("Admin_Escuela_Contenedor")}>
            Escuela
          </button>
          <button onClick={() => setActiveForm("Admin_Perfil_Contenedor")}>
            Perfil Profesional
          </button>
          <button onClick={() => setActiveForm("Admin_Cuerpo")}>
            Cuerpo Académico
          </button>
          <button onClick={() => setActiveForm("Admin_Seminario")}>
            Seminario de título
          </button>
          <button onClick={() => setActiveForm("Admin_CEC_Contenedor")}>
            CEC
          </button>
          <button onClick={() => setActiveForm("Admin_Empleos")}>
            Empleos y prácticas
          </button>
          <button onClick={() => setActiveForm("Admin_Documentos")}>
            Documentos
          </button>
        </Collapsible>
        <Collapsible trigger="Departamento de Agronomía ⮟">
          <button onClick={() => setActiveForm("Admin_Nosotros_Contenedor")}>
            Nosotros
          </button>
          <button onClick={() => setActiveForm("Admin_Academicos")}>
            Académicos
          </button>
          <button onClick={() => setActiveForm("Admin_Servicios_Contenedor")}>
            Servicios
          </button>
        </Collapsible>
        <Collapsible trigger="Revista ⮟">
          <button
            onClick={() => setActiveForm("Admin_Instrucciones_Contenedor")}>
            Intrucciones a los autores
          </button>
          <button onClick={() => setActiveForm("Admin_Descargas")}>
            Descargas
          </button>
        </Collapsible>
        <Collapsible trigger="Footer ⮟">
          <button onClick={() => setActiveForm("Admin_Footer")}>Footer</button>
        </Collapsible>
      </div>
      <div className="cuerpoDashboard">
        <div className="topbar">Dashboard</div>
        {renderForm()}
      </div>
    </div>
  );
}

export default Dashboard;
