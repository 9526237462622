import React from "react";
import Navbar from "../components/Navbar";
import Margen from "../components/Margen";
import Footer from "../components/Footer";
import Panel_Descargas from "../components/Panel_Descargas";
function Descargas() {
  return (
    <div>
      <Navbar />
      <Margen />
      <Panel_Descargas />
      <Footer />
    </div>
  );
}

export default Descargas;
