import React, { useState, useEffect } from "react";
import "../css/Panel_Academicos.css";
import no_image from "../images/no_image.jfif";
import Modal from "react-modal";
import { CSSTransition } from "react-transition-group";
import axios from "axios";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function Panel_Academicos() {
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [academicoSeleccionado, setAcademicoSeleccionado] = useState(null);
  const [academicos, setAcademicos] = useState([]);

  const fetchAcademicos = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agrouls_backend/academicos.php`
    );
    setAcademicos(response.data);
  };

  const handleBodyScroll = (isOpen) => {
    if (isOpen) {
      document.body.style.overflow = "hidden"; // Deshabilita el desplazamiento
    } else {
      document.body.style.overflow = "auto"; // Habilita el desplazamiento
    }
  };

  useEffect(() => {
    handleBodyScroll(modalIsOpen);
    fetchAcademicos();

    return () => {
      document.body.style.overflow = "auto"; // Asegura que el desplazamiento se habilite al salir
    };
  }, [modalIsOpen]);

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }
  const handleOpenModal = (academico) => {
    setAcademicoSeleccionado(academico);
    setIsOpen(true);
  };

  return (
    <div className="ContendorAcademicos">
      <h1>Academicos departamento de agronomía</h1>
      <div className="contendorAcademicos">
        {academicos.map((academico, index) => (
          <div className="academico" key={index}>
            <div className="imagenAcademico">
              <img
                src={`${process.env.REACT_APP_API_URL}/agrouls_backend/uploads/${academico.imagen}`}
                onError={(e) => (e.target.src = no_image)}></img>
            </div>
            <h4>{academico.nombre}</h4>
            <p>{academico.titulo}</p>
            <p className="correoAcademico">{academico.correo}</p>
            <button onClick={() => handleOpenModal(academico)}>
              VER MÁS INFO ➜
            </button>
          </div>
        ))}
      </div>
      <CSSTransition
        in={modalIsOpen}
        timeout={300}
        classNames="modal-transition"
        unmountOnExit>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal">
          <button className="close" onClick={() => setIsOpen(false)}>
            ✖
          </button>
          <div className="contenedorImagenModal">
            <img
              src={`${process.env.REACT_APP_API_URL}/agrouls_backend/uploads/${academicoSeleccionado?.imagen}`}
              onError={(e) => (e.target.src = no_image)}></img>
          </div>
          <div className="contenedorTextoModal">
            <h4>{academicoSeleccionado?.nombre}</h4>
            <p>{academicoSeleccionado?.titulo}</p>
            <p className="correoAcademico">{academicoSeleccionado?.correo}</p>
            <p className="especialidad">{academicoSeleccionado?.descripcion}</p>
          </div>
        </Modal>
      </CSSTransition>
    </div>
  );
}

export default Panel_Academicos;
