import React from "react";
import Admin_Nosotros_1 from "./Admin_Nosotros_1";
import Admin_Nosotros_2 from "./Admin_Nosotros_2";
import Admin_Nosotros_3 from "./Admin_Nosotros_3";

function Admin_Nosotros_Contenedor() {
  return (
    <div>
      <Admin_Nosotros_1 />
      <Admin_Nosotros_2 />
      <Admin_Nosotros_3 />
    </div>
  );
}

export default Admin_Nosotros_Contenedor;
