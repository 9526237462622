import React, { useState, useEffect } from "react";
import "../css/Panel_Cuerpo.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import no_image from "../images/no_image.jfif";
import Modal from "react-modal";
import { CSSTransition } from "react-transition-group";
import axios from "axios";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function Panel_Cuerpo() {
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [academicoSeleccionado, setAcademicoSeleccionado] = useState(null);

  const [cuerpo, setCuerpo] = useState([]);

  const fetchCuerpo = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agrouls_backend/cuerpo.php`
    );
    setCuerpo(response.data);
  };

  const handleBodyScroll = (isOpen) => {
    if (isOpen) {
      document.body.style.overflow = "hidden"; // Deshabilita el desplazamiento
    } else {
      document.body.style.overflow = "auto"; // Habilita el desplazamiento
    }
  };

  useEffect(() => {
    handleBodyScroll(modalIsOpen);
    fetchCuerpo();

    return () => {
      document.body.style.overflow = "auto"; // Asegura que el desplazamiento se habilite al salir
    };
  }, [modalIsOpen]);

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }
  const handleOpenModal = (academico) => {
    setAcademicoSeleccionado(academico);
    setIsOpen(true);
  };

  // const academicos_agronomia = [
  //   {
  //     nombre: "Adriana Benavides López",
  //     titulo: "Ingeniera Agrónoma",
  //     correo: "abenavid@userena.cl",
  //     descripcion: "Doctor en Producción Frutícola y Postcosecha",
  //   },
  //   {
  //     nombre: "Aldo Martini Bravo",
  //     titulo: "Ingeniero Agrónomo",
  //     correo: "amartini@userena.cl",
  //     descripcion: "Licenciado en Ciencias Agropecuarias",
  //   },
  //   {
  //     nombre: "Aldo Tapia Araya",
  //     titulo: "Ingeniero Agrónomo",
  //     correo: "aatapia@userena.cl",
  //     descripcion: "Licenciado en Agronomía",
  //   },
  //   {
  //     nombre: "Carlos Anes Arriagada",
  //     titulo: "Ingeniero Agrónomo",
  //     correo: "canes@userena.cl",
  //     descripcion: "Licenciado en Agronomía",
  //   },
  //   {
  //     nombre: "Carlos Villalobos Castillo",
  //     titulo: "Ingeniero Agrónomo",
  //     correo: "carlos.villalobosc@userena.cl",
  //     descripcion: "Licenciado en Agronomía",
  //   },
  //   {
  //     nombre: "Carmen Jorquera Jaramillo",
  //     titulo: "Ingeniero Agrónomo",
  //     correo: "cjorque@userena.cl",
  //     descripcion: "Doctor (C) en Biología y Ecología Aplicada",
  //   },
  //   {
  //     nombre: "Carol Krausz Barrientos",
  //     titulo: "Ingeniero Agrónomo",
  //     correo: "ckrausz@userena.cl",
  //     descripcion: "Magíster (C) en Planificación y Gestión Educacional",
  //   },
  //   {
  //     nombre: "Claudio Cienfuegos Garrido",
  //     titulo: "Contador Auditor",
  //     correo: "claudio.cienfuegos@userena.cl",
  //     descripcion: "Magister MBA",
  //   },
  //   {
  //     nombre: "Claudio Pinto Cornejo",
  //     titulo: "Ingeniero Comercial",
  //     correo: "pintocornejoclaudio@gmail.com",
  //     descripcion:
  //       "Licenciado en Administración de Empresas y Magister en Ciencias Sociales Aplicadas",
  //   },
  //   {
  //     nombre: "Cristian Blanco Alfaro",
  //     titulo: "Sociólogo",
  //     correo: "cblanco@userena.cl",
  //     descripcion:
  //       "Licenciado en Sociología Magíster en Docencia para la Educación",
  //   },
  //   {
  //     nombre: "Diana Tello Jiménez",
  //     titulo: "Economista",
  //     correo: "dtello@userena.cl",
  //     descripcion: "Profesional en Economìa Mg.",
  //   },
  //   {
  //     nombre: "Edith Valdivia Delgado",
  //     titulo: "Contador Público Auditor",
  //     correo: "evaldivia@userena.cl",
  //     descripcion: "Magister en Educación, Mención Política y Gestión",
  //   },
  //   {
  //     nombre: "Exequiel Sandoval Quezada",
  //     titulo: "Ingeniero Agrónomo",
  //     correo: "esandoval@userena.cl",
  //     descripcion:
  //       "Magister en Administración y Gestión de Empresas. Master of Business, Administration-MBA.",
  //   },
  //   {
  //     nombre: "Fermin Alfaro Kong",
  //     titulo: "Ingeniero Agrónomo",
  //     correo: "fmalfaro@userena.cl",
  //     descripcion: "Magíster en Ciencias Biológicas",
  //   },
  //   {
  //     nombre: "Héctor Reyes Serrano",
  //     titulo: "Ingeniero Agrónomo",
  //     correo: "freyes@userena.cl",
  //     descripcion: "Doctor en Geografía, Gestión y Ambiente",
  //   },
  //   {
  //     nombre: "José Luis Ortíz Allende",
  //     titulo: "Ingeniero Agrónomo",
  //     correo: "jose.ortiz@userena.cl",
  //     descripcion: "Licenciado en Agronomía",
  //   },
  //   {
  //     nombre: "Kattia Araya Rypowski",
  //     titulo: "Ingeniero Agrónomo",
  //     correo: "correo",
  //     descripcion: "descripcion",
  //   },
  //   {
  //     nombre: "Leticia Jofré Bruna",
  //     titulo: "Ingeniero Agrónomo",
  //     correo: "leticiajbruna@gmail.com",
  //     descripcion: "Licenciada en Agronomía",
  //   },
  //   {
  //     nombre: "Marcelo Martínez Vásquez",
  //     titulo: "Médico Veterinario",
  //     correo: "marcelo.martinez@userena.cl",
  //     descripcion:
  //       "Licenciado en Ciencias Veterinarias. Diplomado en Agroecología y Desarrollo Rural Sustentable.",
  //   },
  //   {
  //     nombre: "Marcia Yáñez Acevedo",
  //     titulo: "Ingeniero Agrónomo",
  //     correo: "marciayanez@userena.cl",
  //     descripcion: "Licenciado en Agronomía",
  //   },
  //   {
  //     nombre: "Mauricio Cortes Urtubia",
  //     titulo: "Ingeniero Agrónomo",
  //     correo: "macortesu@userena.cl",
  //     descripcion: "Licenciado en Agronomía",
  //   },
  //   {
  //     nombre: "Pablo Álvarez Latorre",
  //     titulo: "Ingeniero Agrónomo",
  //     correo: "pabloa@userena.cl",
  //     descripcion: "Doctor en Geografía, Gestión y Ambiente",
  //   },
  //   {
  //     nombre: "Teresa Roco Bugueño",
  //     titulo: "Ingeniera en Alimentos",
  //     correo: "trocob@userena.cl",
  //     descripcion: "Magister en Ciencias, mención Ingeniería en Alimentos",
  //   },
  // ];
  // const academicos_artes = [
  //   {
  //     nombre: "Elvis Campos Palacios",
  //     titulo: "Profesor de Estado en Inglés",
  //     correo: "ecampos@userena.cl",
  //     descripcion: "Licenciado en Educación",
  //   },
  //   {
  //     nombre: "Perla Antiquera Araya",
  //     titulo: "Profesora de Estado en Inglés",
  //     correo: "perla.antiquera@userena.cl",
  //     descripcion: "Licenciada en Educación",
  //   },
  //   {
  //     nombre: "Gina Mauad Galvez",
  //     titulo: "Profesor de Inglés",
  //     correo: "gina.mauad@userena.cl",
  //     descripcion: "Licenciado en Educación",
  //   },
  // ];
  // const academicos_biologia = [
  //   {
  //     nombre: "Angeline Jean Aline Bertin",
  //     titulo: "Biólogo",
  //     correo: "abertin@userena.cl",
  //     descripcion: "Doctorado",
  //   },
  //   {
  //     nombre: "Carlos Zuleta Ramos",
  //     titulo: "Profesor de Estado en Biología y Ciencias Naturales",
  //     correo: "czuleta@userena.cl",
  //     descripcion: "Magíster en Ciencias Biológicas",
  //   },
  //   {
  //     nombre: "Claudia Barraza Zepeda",
  //     titulo: "Profesora de Estado en Biología y Ciencias Naturales",
  //     correo: "cbarraza@userena.cl",
  //     descripcion:
  //       "Magíster en Ciencias Biológicas, mención Ecología de Zonas áridas",
  //   },
  //   {
  //     nombre: "Cristian Araya Jaime",
  //     titulo: "Profesor de Estado en Biología y Ciencias Naturales",
  //     correo: "cristian.arayaj3@userena.cl",
  //     descripcion:
  //       "Magister en Cs. Médicas y Biológicas, Mención Genética. Doctor (PhD) en Cs. Biológicas, Mención Genética.",
  //   },
  //   {
  //     nombre: "Cristian Ibáñez Gutiérrez",
  //     titulo: "Ingeniero Forestal",
  //     correo: "cibanez@userena.cl",
  //     descripcion:
  //       "Doctor en Biotecnología de plantas y microorganismos asociados",
  //   },
  //   {
  //     nombre: "Fermin Alfaro Kong",
  //     titulo: "Ingeniero Agrónomo",
  //     correo: "fmalfaro@userena.cl",
  //     descripcion: "Magíster en Ciencias Biológicas",
  //   },
  //   {
  //     nombre: "Francisco Squeo Porcile",
  //     titulo: "Licenciado en Ciencias, mención Biología",
  //     correo: "f_squeo@userena.cl",
  //     descripcion: "Doctor en Biología",
  //   },
  //   {
  //     nombre: "Gina Arancio Jofre",
  //     titulo: "Profesor de Estado en Biología y Ciencias Naturales",
  //     correo: "garancio@userena.cl",
  //     descripcion: "Licenciado en Educación en Biología",
  //   },
  //   {
  //     nombre: "Jaime Pizarro Araya",
  //     titulo: "Profesor de Estado en Biología y Ciencias Naturales",
  //     correo: "japizarro@userena.cl",
  //     descripcion: "Magíster en Ciencias Biológicas con Mención en Zoología",
  //   },
  //   {
  //     nombre: "Jorge Cepeda Pizarro",
  //     titulo: "Profesor Normalista, Profesor de Estado en Biología y Ciencia",
  //     correo: "jcepeda@userena.cl",
  //     descripcion:
  //       "Master of Science. Ph. D. en Biología. New Mexico State University.",
  //   },
  //   {
  //     nombre: "Nancy Olivares Briceño",
  //     titulo: "Profesor de Estado en biología y Ciencias Naturales",
  //     correo: "nolivares@userena.cl",
  //     descripcion:
  //       "Magíster en Ciencias Biológicas con mención Ecología de Zonas Áridas",
  //   },
  //   {
  //     nombre: "Rodomiro Osorio Barahona",
  //     titulo: "Profesor de Estado en Biología y Ciencias Naturales",
  //     correo: "rosorio@userena.cl",
  //     descripcion:
  //       "Doctor en Biología y Fisiología de Organismos y Poblaciones",
  //   },
  //   {
  //     nombre: "Veronica Plaza Santana",
  //     titulo: "Profesor de Estado en Biología y Ciencias Naturales",
  //     correo: "vplaza@userena.cl",
  //     descripcion: "Magister en Ciencias Biológicas",
  //   },
  // ];
  // const academicos_fisica = [
  //   {
  //     nombre: "Ewin Sánchez Casanga",
  //     titulo: "Licenciado en Física",
  //     correo: "esanchez@userena.cl",
  //     descripcion: "Magister en Ciencias Físicas",
  //   },
  //   {
  //     nombre: "Julio MarÍn Malebran",
  //     titulo: "Ingeniero Físico",
  //     correo: "jmarin@userena.cl",
  //     descripcion: "Magister en Ciencias Físicas",
  //   },
  // ];
  // const academicos_minas = [
  //   {
  //     nombre: "Ivonne Flores Morales",
  //     titulo: "Ingeniero de Ejecución Minas",
  //     correo: "iflores@userena.cl",
  //     descripcion: "descripcion",
  //   },
  //   {
  //     nombre: "Ricardo Oyarzun Lucero",
  //     titulo: "Ingeniero Agrónomo",
  //     correo: "royarzun@userena.cl",
  //     descripcion:
  //       "Magister en Ingeniería Agrícola, mención Recursos Hídricos. PhD Ciencia de la Ingeniería.",
  //   },
  // ];
  // const academicos_matematicas = [
  //   {
  //     nombre: "Christian Lorca Cruz",
  //     titulo: "Profesor de Estado en Matemàticas y Computación",
  //     correo: "clorca@userena.cl",
  //     descripcion: "descripcion",
  //   },
  //   {
  //     nombre: "Eduardo Campos Araya",
  //     titulo: "Ingeniero Civil Industrial",
  //     correo: "eduardo.campos@userena.cl",
  //     descripcion: "descripcion",
  //   },
  //   {
  //     nombre: "Erick de la Barra Olivares",
  //     titulo: "Licenciado en Matemáticas",
  //     correo: "erick.delabarra@userena.cl",
  //     descripcion: "descripcion",
  //   },
  //   {
  //     nombre: "Giorgio Reveco Barraza",
  //     titulo: "Licenciado en Física con Mención en Física Aplicada",
  //     correo: "greveco@userena.cl",
  //     descripcion: "descripcion",
  //   },
  // ];
  // const academicos_quimicas = [
  //   {
  //     nombre: "Elizabeth Arancibia Araya",
  //     titulo: "Químico Laboratorista",
  //     correo: "earancib@userena.cl",
  //     descripcion: "Magíster en Educación en Ciencias",
  //   },
  //   {
  //     nombre: "Ivan Fernández Rojas",
  //     titulo: "Químico Laboratorista",
  //     correo: "ifernand@userena.cl",
  //     descripcion: "Doctor en Ciencias",
  //   },
  //   {
  //     nombre: "Juan Hidalgo Maldonado",
  //     titulo: "Profesor de Estado en Química y Ciencias Naturales",
  //     correo: "jchidalgo@userena.cl",
  //     descripcion: "Licenciado en Educación en Química y Ciencias Naturales",
  //   },
  //   {
  //     nombre: "Natalia Rojas Villar",
  //     titulo: "Químico Laboratorista",
  //     correo: "natalia.rojasv1@userena.cl",
  //     descripcion: "Licenciatura en Química",
  //   },
  //   {
  //     nombre: "Sebastian Pizarro San Francisco",
  //     titulo: "Químico Laboratorista",
  //     correo: "sgpizarro@userena.cl",
  //     descripcion: "Doctor en Química",
  //   },
  // ];

  return (
    <div className="ContendorCuerpo">
      <h1>Cuerpo Académico</h1>
      <Tabs>
        <TabList>
          <Tab>Dpto. Agronomía</Tab>
          <Tab>Dpto. Artes y Letras</Tab>
          <Tab>Dpto. Biología</Tab>
          <Tab>Dpto. Física</Tab>
          <Tab>Dpto. Ingeniería de Minas</Tab>
          <Tab>Dpto. Matemáticas</Tab>
          <Tab>Dpto. Química</Tab>
        </TabList>
        <TabPanel className="contendorAcademicos">
          {cuerpo
            .filter((cuerpo) => cuerpo.departamento === "Agronomía")
            .map((cuerpo, index) => (
              <div className="academico" key={index}>
                <div className="imagenAcademico">
                  <img
                    src={`${process.env.REACT_APP_API_URL}/agrouls_backend/uploads/${cuerpo.imagen}`}
                    onError={(e) => (e.target.src = no_image)}></img>
                </div>
                <h4>{cuerpo.nombre}</h4>
                <p>{cuerpo.titulo}</p>
                <p className="correoAcademico">{cuerpo.correo}</p>
                <button onClick={() => handleOpenModal(cuerpo)}>
                  VER MÁS INFO ➜
                </button>
              </div>
            ))}
        </TabPanel>
        <TabPanel className="contendorAcademicos">
          {cuerpo
            .filter((cuerpo) => cuerpo.departamento === "Artes y Letras")
            .map((cuerpo, index) => (
              <div className="academico" key={index}>
                <div className="imagenAcademico">
                  <img
                    src={`${process.env.REACT_APP_API_URL}/agrouls_backend/uploads/${cuerpo.imagen}`}
                    onError={(e) => (e.target.src = no_image)}></img>
                </div>
                <h4>{cuerpo.nombre}</h4>
                <p>{cuerpo.titulo}</p>
                <p className="correoAcademico">{cuerpo.correo}</p>
                <button onClick={() => handleOpenModal(cuerpo)}>
                  VER MÁS INFO ➜
                </button>
              </div>
            ))}
        </TabPanel>
        <TabPanel className="contendorAcademicos">
          {cuerpo
            .filter((cuerpo) => cuerpo.departamento === "Biología")
            .map((cuerpo, index) => (
              <div className="academico" key={index}>
                <div className="imagenAcademico">
                  <img
                    src={`${process.env.REACT_APP_API_URL}/agrouls_backend/uploads/${cuerpo.imagen}`}
                    onError={(e) => (e.target.src = no_image)}></img>
                </div>
                <h4>{cuerpo.nombre}</h4>
                <p>{cuerpo.titulo}</p>
                <p className="correoAcademico">{cuerpo.correo}</p>
                <button onClick={() => handleOpenModal(cuerpo)}>
                  VER MÁS INFO ➜
                </button>
              </div>
            ))}
        </TabPanel>
        <TabPanel className="contendorAcademicos">
          {cuerpo
            .filter((cuerpo) => cuerpo.departamento === "Física")
            .map((cuerpo, index) => (
              <div className="academico" key={index}>
                <div className="imagenAcademico">
                  <img
                    src={`${process.env.REACT_APP_API_URL}/agrouls_backend/uploads/${cuerpo.imagen}`}
                    onError={(e) => (e.target.src = no_image)}></img>
                </div>
                <h4>{cuerpo.nombre}</h4>
                <p>{cuerpo.titulo}</p>
                <p className="correoAcademico">{cuerpo.correo}</p>
                <button onClick={() => handleOpenModal(cuerpo)}>
                  VER MÁS INFO ➜
                </button>
              </div>
            ))}
        </TabPanel>
        <TabPanel className="contendorAcademicos">
          {cuerpo
            .filter((cuerpo) => cuerpo.departamento === "Ingeniería de Minas")
            .map((cuerpo, index) => (
              <div className="academico" key={index}>
                <div className="imagenAcademico">
                  <img
                    src={`${process.env.REACT_APP_API_URL}/agrouls_backend/uploads/${cuerpo.imagen}`}
                    onError={(e) => (e.target.src = no_image)}></img>
                </div>
                <h4>{cuerpo.nombre}</h4>
                <p>{cuerpo.titulo}</p>
                <p className="correoAcademico">{cuerpo.correo}</p>
                <button onClick={() => handleOpenModal(cuerpo)}>
                  VER MÁS INFO ➜
                </button>
              </div>
            ))}
        </TabPanel>
        <TabPanel className="contendorAcademicos">
          {cuerpo
            .filter((cuerpo) => cuerpo.departamento === "Matemáticas")
            .map((cuerpo, index) => (
              <div className="academico" key={index}>
                <div className="imagenAcademico">
                  <img
                    src={`${process.env.REACT_APP_API_URL}/agrouls_backend/uploads/${cuerpo.imagen}`}
                    onError={(e) => (e.target.src = no_image)}></img>
                </div>
                <h4>{cuerpo.nombre}</h4>
                <p>{cuerpo.titulo}</p>
                <p className="correoAcademico">{cuerpo.correo}</p>
                <button onClick={() => handleOpenModal(cuerpo)}>
                  VER MÁS INFO ➜
                </button>
              </div>
            ))}
        </TabPanel>
        <TabPanel className="contendorAcademicos">
          {cuerpo
            .filter((cuerpo) => cuerpo.departamento === "Química")
            .map((cuerpo, index) => (
              <div className="academico" key={index}>
                <div className="imagenAcademico">
                  <img
                    src={`${process.env.REACT_APP_API_URL}/agrouls_backend/uploads/${cuerpo.imagen}`}
                    onError={(e) => (e.target.src = no_image)}></img>
                </div>
                <h4>{cuerpo.nombre}</h4>
                <p>{cuerpo.titulo}</p>
                <p className="correoAcademico">{cuerpo.correo}</p>
                <button onClick={() => handleOpenModal(cuerpo)}>
                  VER MÁS INFO ➜
                </button>
              </div>
            ))}
        </TabPanel>
      </Tabs>
      <CSSTransition
        in={modalIsOpen}
        timeout={300}
        classNames="modal-transition"
        unmountOnExit>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal">
          <button className="close" onClick={() => setIsOpen(false)}>
            ✖
          </button>
          <div className="contenedorImagenModal">
            <img
              src={`${process.env.REACT_APP_API_URL}/agrouls_backend/uploads/${academicoSeleccionado?.imagen}`}
              onError={(e) => (e.target.src = no_image)}></img>
          </div>
          <div className="contenedorTextoModal">
            <h4>{academicoSeleccionado?.nombre}</h4>
            <p>{academicoSeleccionado?.titulo}</p>
            <p className="correoAcademico">{academicoSeleccionado?.correo}</p>
            <p className="especialidad">{academicoSeleccionado?.descripcion}</p>
          </div>
        </Modal>
      </CSSTransition>
    </div>
  );
}
export default Panel_Cuerpo;
