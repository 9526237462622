import React from "react";
import Navbar from "../components/Navbar";
import Margen from "../components/Margen";
import Footer from "../components/Footer";
import Panel_Empleos from "../components/Panel_Empleos";

function Empleos() {
  return (
    <div>
      <Navbar />
      <Margen />
      <Panel_Empleos />
      <Footer />
    </div>
  );
}

export default Empleos;
