import React from "react";
import "../css/Panel_Empleos.css";

function Panel_Empleos() {
  return (
    <div className="ContenedorEmpleos">
      <h1>Empleos y Prácticas</h1>
      <p>
        EN ESTA SECCIÓN PODRÁS ENCONTRAR OFERTAS DE PRÁCTICAS PROFESIONALES Y
        OPORTUNIDADES LABORALES PARA ESTUDIANTES, EGRESADOS Y TITULADOS DE
        INGENIERÍA AGRONÓMICA.
      </p>
      <div className="sinOfertas">
        POR AHORA, NO EXISTE OFERTAS DISPONIBLES.
      </div>
    </div>
  );
}

export default Panel_Empleos;
