import React from "react";
import Navbar from "../components/Navbar";
import Slider from "../components/Slider";
import Informacion from "../components/Informacion";
import Actualidad from "../components/Actualidad";
import Datos from "../components/Datos";
import Actividades from "../components/Actividades";
import Footer from "../components/Footer";
import "../css/Inicio.css";

function Inicio() {
  return (
    <div className="Inicio">
      <Navbar />
      <Slider />
      <div className="contenedorCentral">
        <Informacion />
        <Actualidad />
        <Datos />
        <Actividades />
      </div>
      <Footer />
    </div>
  );
}

export default Inicio;
