import React, { useState, useEffect } from "react";
import "../css/Footer.css";
import axios from "axios";

function Footer() {
  const [footer, setFooter] = useState([]);

  const fetchFooter = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agrouls_backend/footer.php`
    );
    setFooter(response.data);
  };

  useEffect(() => {
    fetchFooter();
  }, []);

  return (
    <div className="Footer">
      {footer.map((footer, index) => (
        <div key={index}>
          <div className="footerSuperior">
            <img
              src={`${process.env.REACT_APP_API_URL}/agrouls_backend/uploads/${footer.imagen}`}></img>
          </div>
          <div className="footerInferiror">
            <p>{footer.direccionLaSerena}</p>
            <p>{footer.direccionOvalle}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Footer;
