import React, { useState, useEffect } from "react";
import "../css/Actualidad.css";
import axios from "axios";
import { CSSTransition } from "react-transition-group";

function Actualidad() {
  const [noticias, setNoticias] = useState([]);
  const [selectedNoticia, setSelectedNoticia] = useState(null);
  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const fetchActualidad = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agrouls_backend/actualidad.php`
    );
    setNoticias(response.data);
  };

  const handleBodyScroll = (isOpen) => {
    if (isOpen) {
      document.body.style.overflow = "hidden"; // Deshabilita el desplazamiento
    } else {
      document.body.style.overflow = "auto"; // Habilita el desplazamiento
    }
  };

  useEffect(() => {
    handleBodyScroll(isModalOpen2);
    fetchActualidad();

    return () => {
      document.body.style.overflow = "auto"; // Asegura que el desplazamiento se habilite al salir
    };
  }, [isModalOpen2]);

  return (
    <div className="Actualidad">
      <h1 className="tituloActualidad">ACTUALIDAD</h1>
      <div className="centralPanelNoticias">
        {noticias.slice(0, 4).map((noticia) => {
          const formattedDate =
            noticia.fecha === "0000-00-00" || !noticia.fecha
              ? ""
              : noticia.fecha;
          return (
            <div
              className="contenedorNoticias"
              key={noticia.id}
              onClick={() => {
                setIsModalOpen2(true);
                setSelectedNoticia(noticia);
              }}>
              <h1 className="tituloNoticias">{noticia.titulo}</h1>
              {formattedDate && (
                <h3 className="fechaNoticia">{formattedDate}</h3>
              )}

              <div className="imagenDescripcion">
                <img
                  className="imagenNoticia"
                  src={`${process.env.REACT_APP_API_URL}/agrouls_backend/uploads/${noticia.imagen}`}
                  alt={noticia.titulo}
                />
              </div>
            </div>
          );
        })}
      </div>
      <CSSTransition
        in={isModalOpen2 && selectedNoticia !== null}
        timeout={300}
        classNames="modal"
        unmountOnExit>
        <div className="modal" onClick={() => setIsModalOpen2(false)}>
          <div
            className="modal-noticias"
            onClick={(e) => e.stopPropagation()}
            key={selectedNoticia?.id}>
            <h1>{selectedNoticia?.titulo}</h1>
            {selectedNoticia?.fecha !== "0000-00-00" && (
              <h2>{selectedNoticia?.fecha}</h2>
            )}
            <img
              src={`${process.env.REACT_APP_API_URL}/agrouls_backend/uploads/${selectedNoticia?.imagen}`}
              loading="lazy"
              alt="Noticia"
            />
            <div className="textoMiembro">
              <i>{selectedNoticia?.enunciado}</i>
              <p>{selectedNoticia?.descripcion}</p>
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
}

export default Actualidad;
