import React, { useState, useEffect } from "react";
import "../css/Panel_Perfil.css";
import axios from "axios";

function Panel_Perfil() {
  const [capacidad, setCapacidad] = useState([]);
  const [archivo, setArchivo] = useState([]);

  const fetchCapacidad = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agrouls_backend/perfil_1.php`
    );
    setCapacidad(response.data);
  };

  const fetchArchivo = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agrouls_backend/perfil_2.php`
    );
    setArchivo(response.data);
  };

  useEffect(() => {
    fetchCapacidad();
    fetchArchivo();
  }, []);

  return (
    <div className="ContenedorPerfil">
      <h3>
        El Ingeniero Agrónomo de la Universidad de La Serena es un profesional
        que:
      </h3>
      <ol>
        {capacidad.map((cap, index) => (
          <div key={index}>
            <li>{cap.capacidades}</li>
            <br />
          </div>
        ))}
      </ol>
      <div className="planEstudios">
        {archivo.map((file, index) => (
          <div key={index}>
            <button>PLAN DE ESTUDIOS</button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Panel_Perfil;
