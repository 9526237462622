import React from "react";
import Admin_Escuela_1 from "./Admin_Escuela_1";
import Admin_Escuela_2 from "./Admin_Escuela_2";
import Admin_Escuela_3 from "./Admin_Escuela_3";

function Admin_Escuela_Contenedor() {
  return (
    <div>
      <Admin_Escuela_1 />
      <Admin_Escuela_2 />
      <Admin_Escuela_3 />
    </div>
  );
}

export default Admin_Escuela_Contenedor;
