import React, { useState, useEffect } from "react";
import "../css/Panel_Servicios.css";
import axios from "axios";

function Panel_Servicios() {
  const [servicios, setServicios] = useState([]);
  const [tema, setTema] = useState([]);

  const fetchServicios = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agrouls_backend/servicios_1.php`
    );
    setServicios(response.data);
  };

  const fetchTema = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agrouls_backend/servicios_2.php`
    );
    setTema(response.data);
  };

  useEffect(() => {
    fetchServicios();
    fetchTema();
  }, []);

  return (
    <div className="ContenedorServicios">
      <h1>Servicios</h1>
      <p>
        El Departamento de Agronomía tiene a disposición del sector
        agroalimentario los siguientes servicios:
      </p>
      <ul>
        {servicios.map((servicio) => (
          <li key={servicio.id}>{servicio.servicio}</li>
        ))}
        <ul>
          {tema.map((tema) => (
            <li key={tema.id}>{tema.tema}</li>
          ))}
        </ul>
      </ul>
    </div>
  );
}

export default Panel_Servicios;
