import React, { useState, useEffect } from "react";
import "../css/Panel_Escuela.css";
import axios from "axios";

function Panel_Escuela() {
  const [escuela, setEscuela] = useState([]);
  const [proposito, setProposito] = useState([]);
  const [autor, setAutor] = useState([]);

  const fetchEscuela = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agrouls_backend/escuela_1.php`
    );
    setEscuela(response.data);
  };

  const fetchProposito = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agrouls_backend/escuela_2.php`
    );
    setProposito(response.data);
  };

  const fetchAutor = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agrouls_backend/escuela_3.php`
    );
    setAutor(response.data);
  };

  useEffect(() => {
    fetchEscuela();
    fetchProposito();
    fetchAutor();
  }, []);

  return (
    <div className="ContenedorEscuela">
      <h1>Escuela</h1>
      {escuela.map((escuela) => (
        <div key={escuela.id}>
          <p>{escuela.presentacion}</p>
        </div>
      ))}
      {proposito.map((proposito) => (
        <ul>
          <li>{proposito.proposito}</li>
        </ul>
      ))}
      {autor.map((autor) => (
        <div>
          <p className="firmaDirector">{autor.nombre}</p>
          <p className="firmaDirector">{autor.cargo}</p>
        </div>
      ))}
    </div>
  );
}

export default Panel_Escuela;
