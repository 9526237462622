import React, { useState, useEffect } from "react";
import "../css/Panel_Seminario.css";
import axios from "axios";
import { CSSTransition } from "react-transition-group";

function Panel_Seminario() {
  const [seminarios, setSeminarios] = useState([]);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [selectedSeminario, setSelectedSeminario] = useState(null);

  const fetchSeminarios = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agrouls_backend/seminario.php`
    );
    setSeminarios(response.data);
  };

  const handleBodyScroll = (isOpen) => {
    if (isOpen) {
      document.body.style.overflow = "hidden"; // Deshabilita el desplazamiento
    } else {
      document.body.style.overflow = "auto"; // Habilita el desplazamiento
    }
  };

  useEffect(() => {
    handleBodyScroll(isModalOpen2);
    fetchSeminarios();

    return () => {
      document.body.style.overflow = "auto"; // Asegura que el desplazamiento se habilite al salir
    };
  }, [isModalOpen2]);

  return (
    <div className="ContenidoSeminario">
      <h1>Seminario de titulación</h1>
      <table className="tablaSeminarios">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Año</th>
            <th>Titulo</th>
            <th>Clave</th>
          </tr>
        </thead>
        <tbody>
          {seminarios.map((seminario, index) => (
            <tr key={index}>
              <td>{seminario.nombre}</td>
              <td>{seminario.year}</td>
              <td
                className="tituloSeminario"
                onClick={() => {
                  setIsModalOpen2(true);
                  setSelectedSeminario(seminario);
                }}>
                {seminario.titulo}
              </td>
              <td>{seminario.clave}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <CSSTransition
        in={isModalOpen2 && selectedSeminario !== null}
        timeout={300}
        classNames="modal"
        unmountOnExit>
        <div className="modal" onClick={() => setIsModalOpen2(false)}>
          <div
            className="modal-noticias"
            onClick={(e) => e.stopPropagation()}
            key={selectedSeminario?.id}>
            <h1>{selectedSeminario?.titulo}</h1>
            <div className="textoMiembro">
              <p>{selectedSeminario?.descripcion}</p>
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
}

export default Panel_Seminario;
