import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Inicio from "./pages/Inicio";
import Escuela from "./pages/Escuela";
import Perfil from "./pages/Perfil_Profesional";
import Academicos from "./pages/Academicos";
import CEC from "./pages/CEC";
import Contacto from "./pages/Contacto";
import Nosotros from "./pages/Nosotros";
import Descargas from "./pages/Descargas";
import Servicios from "./pages/Servicios";
import Documentos from "./pages/Documentos";
import Instrucciones from "./pages/Instrucciones";
import Seminario from "./pages/Seminario_Titulo";
import Cuerpo from "./pages/Cuerpo_Academico";
import Empleos_Practicas from "./pages/Empleos_Practicas";
import Dashboard from "./pages/Dashboard";
import Modal from "react-modal";
import Login from "./pages/Login";
import PrivateRoute from "./components/PrivateRoute";
import { AuthProvider } from "./context/AuthContext";

Modal.setAppElement("#root");

function App() {
  return (
    <div>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route index element={<Inicio />} />
            <Route path="/inicio" element={<Inicio />} />
            <Route path="/escuela" element={<Escuela />} />
            <Route path="/perfil" element={<Perfil />} />
            <Route path="/cuerpo" element={<Cuerpo />} />
            <Route path="/seminario" element={<Seminario />} />
            <Route path="/cec" element={<CEC />} />
            <Route path="/empleos" element={<Empleos_Practicas />} />
            <Route path="/documentos" element={<Documentos />} />
            <Route path="/nosotros" element={<Nosotros />} />
            <Route path="/academicos" element={<Academicos />} />
            <Route path="/servicios" element={<Servicios />} />
            <Route path="/contacto" element={<Contacto />} />
            <Route path="/instrucciones" element={<Instrucciones />} />
            <Route path="/descargas" element={<Descargas />} />
            <Route path="/login" element={<Login />}></Route>
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route path="*" element={<h1>Error 404: Not found</h1>}></Route>
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </div>
  );
}

export default App;
