import React from "react";
import Admin_CEC_1 from "./Admin_CEC_1";
import Admin_CEC_2 from "./Admin_CEC_2";
import Admin_CEC_3 from "./Admin_CEC_3";

function Admin_CEC_Contenedor() {
  return (
    <div>
      <Admin_CEC_1 />
      <Admin_CEC_2 />
      <Admin_CEC_3 />
    </div>
  );
}
export default Admin_CEC_Contenedor;
