import React from "react";
import Admin_Instrucciones_1 from "./Admin_Instrucciones_1";
import Admin_Instrucciones_2 from "./Admin_Instrucciones_2";

function Admin_Instrucciones_Contenedor() {
  return (
    <div>
      <Admin_Instrucciones_1 />
      <Admin_Instrucciones_2 />
    </div>
  );
}

export default Admin_Instrucciones_Contenedor;
