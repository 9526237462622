import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "../css/Actividades.css";
import axios from "axios";

function Actividades() {
  const [actividades, setActividades] = useState([]);

  const fetchActividades = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agrouls_backend/actividades.php`
    );
    setActividades(response.data);
  };

  useEffect(() => {
    fetchActividades();
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="Actividades">
      <h1>CONOCE ALGUNA DE NUESTRAS ACTIVIDADES</h1>
      <Carousel
        containerClass="carousel-actividad"
        showArrows={true} // Mostrar las flechas para deslizar
        infiniteLoop={false} // No hacer un loop infinito
        showStatus={false} // Ocultar el contador de páginas
        showThumbs={false} // Ocultar los thumbnails
        draggable={false} // No permitir que el usuario pueda arrastrar los elementos
        responsive={responsive}>
        {actividades.map((actividad, index) => (
          <div key={index} className="actividad">
            <div className="tituiloActividad">
              <a>{actividad.titulo}</a>
            </div>
            <img
              src={`${process.env.REACT_APP_API_URL}/agrouls_backend/uploads/${actividad.imagen}`}></img>
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default Actividades;
