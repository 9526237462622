import React, { useState, useEffect } from "react";
import "../css/Panel_CEC.css";
import axios from "axios";

function Panel_CEC() {
  const [cec, setCEC] = useState([]);
  const [integrantes, setIntegrantes] = useState([]);

  const fetchCEC = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agrouls_backend/cec_1.php`
    );
    setCEC(response.data);
  };

  const fetchIntegrantes = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agrouls_backend/cec_3.php`
    );
    setIntegrantes(response.data);
  };

  useEffect(() => {
    fetchCEC();
    fetchIntegrantes();
  }, []);

  return (
    <div className="ContenedorCEC">
      <h1>Líneas trabajo CEC 2021</h1>
      {cec.map((cec, index) => (
        <div key={index}>
          <p>{cec.presentacion}</p>
          <div className="contenedorDirectorioPrograma">
            <div>
              <h4>Directorio</h4>
              <p>{cec.directorio}</p>
            </div>
            <div>
              <h4>Programa de trabajo</h4>
              <p>{cec.programa}</p>
              <div className="planificacion">
                <a>DESCARGAR</a>
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className="miembrosCEC">
        {integrantes.map((integrante, index) => (
          <div key={index}>
            <div className="imgCEC">
              <img
                src={`${process.env.REACT_APP_API_URL}/agrouls_backend/uploads/${integrante.imagen}`}></img>
            </div>
            <p>{integrante.nombre}</p>
            <p>{integrante.correo}</p>
            <p>{integrante.cargo}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Panel_CEC;
