import React from "react";
import Admin_Servicios_1 from "./Admin_Servicios_1";
import Admin_Servicios_2 from "./Admin_Servicios_2";

function Admin_Servicios_Contenedor() {
  return (
    <div>
      <Admin_Servicios_1 />
      <Admin_Servicios_2 />
    </div>
  );
}

export default Admin_Servicios_Contenedor;
