import React from "react";
import logo from "../images/logo_agrouls.jpg";
import "../css/Navbar.css";

const Navbar = () => {
  return (
    <nav className="navbar">
      <ul className="navbar-logo-menu">
        <img src={logo} alt="logo"></img>
        <div className="navbar-menu">
          <a className="navbar-item" href="/inicio">
            INICIO
          </a>
          <li className="navbar-item">
            INGENIERÍA AGRONÓMICA &#11167;
            <ul className="dropdown-menu">
              <li>
                <a href="/escuela">ESCUELA</a>
              </li>
              <li>
                <a href="/perfil">PERFIL PROFESIONAL</a>
              </li>
              <li>
                <a href="/cuerpo">CUERPO ACADÉMICO</a>
              </li>
              <li>
                <a href="/seminario">SEMINARIO DE TÍTULO</a>
              </li>
              <li>
                <a href="/cec">CEC</a>
              </li>
              <li>
                <a href="/empleos">EMPLEOS Y PRÁCTICAS</a>
              </li>
              <li>
                <a href="/documentos">DOCUMENTOS</a>
              </li>
            </ul>
          </li>
          <li className="navbar-item">
            DEPARTAMENTO DE AGRONOMÍA &#11167;
            <ul className="dropdown-menu">
              <li>
                <a href="/nosotros">NOSOTROS</a>
              </li>
              <li>
                <a href="/academicos">ACADÉMICOS</a>
              </li>
              <li>
                <a href="/servicios">SERVICIOS</a>
              </li>
            </ul>
          </li>
          <li className="navbar-item">
            REVISTA &#11167;
            <ul className="dropdown-menu">
              <li>
                <a href="/instrucciones">INSTRUCCIONES A LOS AUTORES</a>
              </li>
              <li>
                <a href="/descargas">DESCARGAS</a>
              </li>
            </ul>
          </li>
          <a className="navbar-item" href="/contacto">
            CONTACTO
          </a>
        </div>
      </ul>
    </nav>
  );
};

export default Navbar;
