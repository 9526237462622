import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Margen from "../components/Margen";
import Panel_Nosotros from "../components/Panel_Nosotros";

function Nosotros() {
  return (
    <div>
      <Navbar />
      <Margen />
      <Panel_Nosotros />
      <Footer />
    </div>
  );
}

export default Nosotros;
