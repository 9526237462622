import React from "react";
import Navbar from "../components/Navbar";
import Margen from "../components/Margen";
import Footer from "../components/Footer";
import Panel_Documentos from "../components/Panel_Documentos";

function Documentos() {
  return (
    <div>
      <Navbar />
      <Margen />
      <Panel_Documentos />
      <Footer />
    </div>
  );
}

export default Documentos;
