import React, { useState, useEffect } from "react";
import "../css/Panel_Documentos.css";
import icon from "../images/book-64.ico";
import axios from "axios";

function Panel_Documentos() {
  const [documentos, setDocumentos] = useState([]);

  const fetchDocumentos = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agrouls_backend/documentos.php`
    );
    setDocumentos(response.data);
  };

  useEffect(() => {
    fetchDocumentos();
  }, []);

  return (
    <div className="ContenedorDocumentos">
      <h1>Documentos</h1>
      {documentos.map((documento, index) => (
        <div key={index} className="documento">
          <div className="logoDocumento">
            <img src={icon} />
          </div>
          <div className="textoDocumento">
            <h4>{documento.titulo}</h4>
            <a>Descargar</a>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Panel_Documentos;
