import React from "react";
import Navbar from "../components/Navbar";
import Margen from "../components/Margen";
import Footer from "../components/Footer";
import Panel_Servicios from "../components/Panel_Servicios";

function Servicios() {
  return (
    <div>
      <Navbar />
      <Margen />
      <Panel_Servicios />
      <Footer />
    </div>
  );
}

export default Servicios;
